import React from 'react';
import { Container } from 'react-bootstrap'
import BodyORIG from './BodyORIG'

function App() {
  return (
    <Container fluid className="p-0"> 
          <BodyORIG />
    </Container>
  );
}

export default App;
